
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


body{
  background-color: white;
  color: black;
}

*{
  box-sizing: border-box;
  margin: 0;
}

.gradient{
  background: rgb(243,94,152);
  background: linear-gradient(130deg, rgba(243,94,152,1) 0%, rgba(255,48,94,1) 100%);
}

.gradientLight{
  background: rgb(255,188,203);
  background: linear-gradient(158deg, rgba(255,188,203,0.18533350840336138) 0%, rgba(255,255,255,1) 52%, rgba(255,255,255,1) 85%);
}
.softshad{
  box-shadow: 0px 10px 86px -38px rgba(105,105,105,0.75);
  -webkit-box-shadow: 0px 10px 86px -38px rgba(105,105,105,0.75);
  -moz-box-shadow: 0px 10px 86px -38px rgba(105,105,105,0.75);
}
.softshadSecondary{
  box-shadow: 0px 0px 31px -10px rgba(191,34,66,0.1);
  -webkit-box-shadow: 0px 0px 31px -10px rgba(191,34,66,0.1);
  -moz-box-shadow: 0px 0px 31px -10px rgba(191,34,66,0.1);
}